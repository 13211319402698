/**
 * ==================================================================
 * ======================= 支付配置 ===================
 * ==================================================================
 */
import request from '@/utils/request'

/**
 * 获取支付配置选中的授权模式
 */
export function fetchAuthData(params) {
    return request({
        url: '/provider/Provider/getProviderMode',
        method: 'get',
        data: params
    })
}

/**
 * 保存支付配置授权模式
 */
export function editorAuthData(params) {
    return request({
        url: '/provider/Provider/saveProviderMode',
        method: 'post',
        data: params
    })
}
