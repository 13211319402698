<template>
  <div id="new-pay-config" class="modal">
    <div class="modal__content">
      <div class="p-4 text-base font-medium border-b border-gray-200">{{ dialogTitle }}</div>
      <el-form class="p-4" label-position="top" label-width="80px" :model="formData">
        <el-form-item label="应用名称">
          <el-input v-model="formData.name" placeholder="应用名称"></el-input>
        </el-form-item>

        <el-form-item label="支付类型">
          <el-radio-group v-model="formData.pay_type">
            <el-radio label="0">全部</el-radio>
            <el-radio label="1">支付宝支付</el-radio>
            <el-radio label="2">微信支付</el-radio>
          </el-radio-group>
        </el-form-item>



        <!-- 支付宝支付的参数 -->
        <div v-if="formData.pay_type === '1' || formData.pay_type === '0'">
          <el-form-item label="支付宝appid">
            <el-input v-model="formData.ali_app_id" placeholder="支付宝appid"></el-input>
          </el-form-item>
          <el-form-item label="支付宝私钥">
            <el-input v-model="formData.private_key" placeholder="支付宝私钥"></el-input>
          </el-form-item>
          <el-form-item label="支付宝公钥">
            <el-input v-model="formData.public_key" placeholder="支付宝公钥"></el-input>
          </el-form-item>
          <el-form-item label="支付宝网关">
            <el-input v-model="formData.gateway_url" placeholder="支付宝网关"></el-input>
          </el-form-item>
          <el-form-item label="支付宝回调通知">
            <el-input v-model="formData.notify_url" placeholder="支付宝回调通知"></el-input>
          </el-form-item>
        </div>

        <!-- 微信支付的参数 -->
        <div v-if="formData.pay_type === '2' || formData.pay_type === '0'">
          <el-form-item label="微信商户私钥">
            <el-input v-model="formData.pay_wx_apiclient_key" placeholder="微信商户私钥"></el-input>
          </el-form-item>
          <el-form-item label="微信apiV3私钥">
            <el-input v-model="formData.pay_wx_api_key" placeholder="微信apiV3私钥"></el-input>
          </el-form-item>
          <el-form-item label="微信API证书序列号">
            <el-input v-model="formData.pay_wx_api_serial_no" placeholder="微信API证书序列号"></el-input>
          </el-form-item>
          <el-form-item label="微信应用appid">
            <el-input v-model="formData.pay_wx_app_id" placeholder="微信应用appid"></el-input>
          </el-form-item>
          <el-form-item label="微信商户号">
            <el-input v-model="formData.pay_wx_merchant_id" placeholder="微信商户号"></el-input>
          </el-form-item>
          <el-form-item label="微信异步回调地址">
            <el-input v-model="formData.pay_wx_notify_url" placeholder="微信异步回调地址"></el-input>
          </el-form-item>
          <el-form-item label="微信平台证书">
            <el-input v-model="formData.pay_wx_platform_certificates" placeholder="微信平台证书"></el-input>
          </el-form-item>
          <el-form-item label="微信平台证书序列号">
            <el-input v-model="formData.pay_wx_platform_certificates_serial_no" placeholder="微信平台证书序列号"></el-input>
          </el-form-item>
        </div>

        <!-- <el-form-item label="微信appid">
        <el-input v-model="formData.merchant_phone" placeholder="微信appid"></el-input>
      </el-form-item>
      <el-form-item label="微信私钥">
        <el-input v-model="formData.merchant_phone" placeholder="微信私钥"></el-input>
      </el-form-item>
      <el-form-item label="微信公钥">
        <el-input v-model="formData.merchant_phone" placeholder="微信公钥"></el-input>
      </el-form-item> -->

        <el-form-item label="状态">
          <el-radio-group v-model="formData.state">
            <el-radio label="1">启用</el-radio>
            <el-radio label="0">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>

      <div class="px-5 py-3 text-right border-t border-gray-200">
        <button
          type="button"
          class="button w-20 border text-gray-700 mr-1"
          @click="hideProgrammaticallyShowModal()"
        >
          取消
        </button>
        <button type="button" class="button w-20 bg-theme-1 text-white" @click="onSubmit">
          确认
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import request from "@/utils/request";

export default {
  props: {
    appList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialogTitle: '新增',
      formData: {
        id: "", // 编辑时为必传参数
        state: "", // 0:停用中 1:使用中
        name: "", // 应用名称
        pay_type: "", // 支付方式 0:全部 1:支付宝 2:微信
        remark: "测试的测试",
        app_id: "1", // 智莺appid
        ali_app_id: "", // 支付宝配置(如：app_id、public_key、private_key、gateway_url、notify_url)
        public_key: 1, // 支付宝公钥
        private_key: "", // 支付宝秘钥
        gateway_url: "", // 支付宝网关
        notify_url: "", // 支付宝回调通知

        pay_wx_apiclient_key: "", // 微信-商户私钥
        pay_wx_api_key: "", // 微信-apiV3私钥
        pay_wx_api_serial_no: "", // 微信-API证书序列号
        pay_wx_app_id: "", // 微信-应用appid
        pay_wx_merchant_id: "", // 微信-商户号
        pay_wx_notify_url: "", // 微信-异步回调地址
        pay_wx_platform_certificates: "", // 微信-平台证书
        pay_wx_platform_certificates_serial_no: "" // 微信-平台证书序列号
      },
    };
  },
  methods: {
    hideProgrammaticallyShowModal() {
      cash("#new-pay-config").modal("hide"); // 关闭弹窗
    },
    // 确认
    onSubmit() {
      request({
        url: "/appManage/App/addOrEdit",
        method: "POST",
        data: this.formData,
      }).then((res) => {
        if (res.code === 0) {
          this.hideProgrammaticallyShowModal();
          this.$parent.init();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal__content {
  width: 580px;
  border-radius: 12px;
}
.el-form-item {
  margin-bottom: 0px;
}
</style>
