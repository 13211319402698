<template>
  <div id="authorization-model" class="modal">
    <div class="modal__content pl-3 pr-3">
      <div class="pt-4 pb-4 text-base font-medium">授权模式</div>
      <div class="flex mb-4 flex-box">
        <div
          class="border text-gray-700 flex-item p-4 flex"
          :class="{ 'border-theme-1': pattern === '1' }"
          @click="pattern = '1'"
        >
          <div class="img-box">
            <img src="@/assets/img/pintai.png" alt="" />
            <div class="text-md">平台模式</div>
          </div>
          <div>
            <p class="flex">
              <span class="mr-auto">模式介绍</span>
              <i
                class="el-icon-success"
                :style="pattern === '1' ? 'color:#1890FF' : 'color: #8e8e8e'"
              ></i>
            </p>
            <div class="content-md">
              适合支付流水相对较小的企业使用，解决中小企业用户对接支付渠道难，对接门槛高，维护成本高问题，
              无需开发团队即可完成对接，成本更低，也可借助平台优势获得相对单一企业更高的收益，快速完成支付渠道对接，高效快捷。
            </div>
          </div>
        </div>
        <div
          class="border text-gray-700 flex-item p-4 flex"
          :class="{ 'border-theme-1': pattern === '2' }"
          @click="pattern = '2'"
        >
          <div class="img-box">
            <img src="@/assets/img/duli.png" alt="" />
            <div class="text-md">独立模式</div>
          </div>
          <div>
            <p class="flex">
              <span class="mr-auto">模式介绍</span>
              <i
                class="el-icon-success"
                :style="pattern === '2' ? 'color:#1890FF' : 'color: #8e8e8e'"
              ></i>
            </p>
            <div class="content-md">
              适用于支付流水相对较大且有一定开发能力的企业，可以通过支付联盟低成本对接支付宝、微信等主流支付平台，技术服务在线支持，省时省心。
            </div>
          </div>
        </div>
      </div>
      <!-- 平台模式 -->
      <div
        v-show="pattern === '1'"
        class="border text-gray-700 p-4 flex border-theme-1 body-theme-1"
      >
        <div class="flex" style="width: 100%; justify-content: space-around">
          <div class="flex-item">
            <div class="text-base font-medium">平台模式概念</div>
            <div class="item-a-box">
              <img src="@/assets/img/platform.png" alt="" />
            </div>
          </div>

          <div class="flex-item">
            <div class="text-base font-medium">平台模式</div>
            <div class="item-a-box">
              <div style="height: 100%; flex-flow: column" class="flex">
                <p class="mb-2">平台模式详细介绍</p>
                <div class="item-text-a mb-auto">
                  适合支付流水相对较小的企业使用，解决中小企业用户对接支付渠道难，对接门槛高，维护成本高问题，
                  无需开发团队即可完成对接，成本更低，也可借助平台优势获得相对单一企业更高的收益，快速完成支付渠道对接，高效快捷。
                </div>

                <el-button type="primary" class="w-48 mt-auto">切换模式</el-button>
                <p class="mt-2">
                  <el-radio v-model="consent">同意智莺支付联盟服务协议</el-radio>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 独立模式 -->
      <div
        v-show="pattern === '2'"
        class="border text-gray-700 p-4 flex border-theme-1 body-theme-1"
      >
        <div class="flex" style="width: 100%; justify-content: space-around">
          <div class="flex-item">
            <div class="text-base flex font-medium">
              <span>独立模式概念</span>
              <span class="independent-icon">未申请</span>
            </div>
            <div class="item-a-box">
              <img src="@/assets/img/independent.png" alt="" />
            </div>
          </div>

          <div class="flex-items">
            <div class="text-base font-medium">服务期限</div>
            <div class="pt-2 pb-2">
              <el-radio-group v-model="deadline">
                <el-radio :label="1">一年</el-radio>
                <el-radio :label="2">两年</el-radio>
                <el-radio :label="3">三年</el-radio>
              </el-radio-group>
            </div>
            <div class="text-base font-medium">支付金额</div>
            <div style="color: #ff4242; font-size: 10px" class="pt-2 pb-2">¥20000</div>
            <div class="text-base font-medium">支付方式</div>
            <div class="pay-type-box">
              <div class="pay-type-item">
                <div
                  class="item-t"
                  :style="paymentType === 'alipay' ? 'color:#333333' : 'color: #999999'"
                  @click="paymentType = 'alipay'"
                >
                  <img :src="alipayImg" alt="" />
                  <span>支付宝</span>
                  <i
                    class="el-icon-success"
                    :style="paymentType === 'alipay' ? 'color:#1585FF' : 'color: #999999'"
                  ></i>
                </div>
                <div
                  class="item-t"
                  :style="paymentType === 'wechat' ? 'color:#333333' : 'color: #999999'"
                  @click="paymentType = 'wechat'"
                >
                  <img :src="wechatImg" alt="" />
                  <span>微信</span>
                  <i
                    class="el-icon-success"
                    :style="paymentType === 'wechat' ? 'color:#1585FF' : 'color: #999999'"
                  ></i>
                </div>
              </div>

              <el-button style="width: calc(50% - 10px); margin-top: 20px" type="primary"
                >立即支付</el-button
              >
              <p class="mt-4">
                <el-radio v-model="consent">同意智莺支付联盟服务协议</el-radio>
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- 底部 -->
      <div class="px-5 py-3 mt-10 text-right border-t border-gray-200">
        <button
          type="button"
          class="button w-20 border text-gray-700 mr-1"
          @click="hideProgrammaticallyShowModal()"
        >
          取消
        </button>
        <button type="button" class="button w-20 bg-theme-1 text-white" @click="onSubmit">
          确认
        </button>
      </div>
    </div>
  </div>
</template>
<script>
// import request from "../../../utils/request";
export default {
  props: {
    appList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      pattern: "1",
      consent: false,
      deadline: null,
      paymentType: "",
    };
  },

  computed: {
    alipayImg() {
      if (this.paymentType === "alipay") {
        return require("@/assets/img/alipay-2.png");
      } else {
        return require("@/assets/img/alipay-1.png");
      }
    },
    wechatImg() {
      if (this.paymentType === "wechat") {
        return require("@/assets/img/WeChat-2.png");
      } else {
        return require("@/assets/img/WeChat-1.png");
      }
    },
  },
  methods: {
    hideProgrammaticallyShowModal() {
      cash("#authorization-model").modal("hide"); // 关闭弹窗
    },
    // 确认
    onSubmit() {},
    // 获取基础设置
    _fetchData() {

    }
  },
};
</script>

<style lang="scss" scoped>
.modal__content {
  width: 992px;
  border-radius: 12px;
}
.flex-box {
  width: 100%;
  justify-content: space-between;
}

.flex-item {
  width: calc(50% - 13px);
  border-radius: 7px;
  cursor: pointer;
}

.flex-items {
  width: calc(50% - 20px);
  border-radius: 7px;
}

.img-box {
  width: 60px;
  display: flex;
  flex-flow: column;
  text-align: center;
  flex-shrink: 0;
  margin-right: 10px;
}

.img-box > image {
  width: 100%;
}

.text-md {
  font-size: 13px;
  margin-top: 5px;
}

.content-md {
  font-size: 12px;
  color: #999999;
  margin-top: 5px;
}

.body-theme-1 {
  width: 100%;
  border-radius: 12px;
  position: relative;
}

.item-a-box {
  width: 100%;
  height: 300px;
  background: #fcfcfd;
  border: 1px solid #f4f4f7;
  opacity: 1;
  border-radius: 11px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 18px;
}
.item-a-box > img {
  width: 480px;
}

.item-text-a {
  color: #999999;
  font-size: 8px;
}

.independent-icon {
  color: #3a96fb;
  font-size: 12px;
  margin-left: 5px;

  display: flex;
  align-items: center;
}

.independent-icon::before {
  content: "";
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #3a96fb;
  margin-right: 5px;
}

.pay-type-box {
  width: 100%;
  height: 184px;
  background: #fcfcfd;
  border: 1px solid #f4f4f7;
  opacity: 1;
  margin-top: 10px;
  border-radius: 9px;
  padding: 18px;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
}

.pay-type-item {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: auto;
}

.item-t {
  width: calc(50% - 10px);
  height: 36px;
  background: #efeff0;
  border: 1px solid #e2e8f0;
  opacity: 1;
  border-radius: 12px;
  box-sizing: border-box;
  padding: 6px 9px;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #999999;
  img {
    height: 100%;
    margin-right: 10px;
  }
  i {
    margin-left: auto;
    font-size: 18px;
  }
}
</style>
