import { render, staticRenderFns } from "./Cashbox.vue?vue&type=template&id=1214ce37&scoped=true&"
import script from "./Cashbox.vue?vue&type=script&lang=js&"
export * from "./Cashbox.vue?vue&type=script&lang=js&"
import style0 from "./Cashbox.vue?vue&type=style&index=0&id=1214ce37&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1214ce37",
  null
  
)

export default component.exports