<template>
  <div>
    <div class="flex items-center pt-8 pb-4">
      <span class="mr-auto text-lg font-medium truncate ml-3 font-bold">支付配置</span>
      <div class="flex items-center">
        <span
          class="button inline-block bg-theme-1 mr-3 text-white"
          @click="handleClick({})"
        >
          <i class="el-icon-plus mr-1"></i> <span>新建</span></span
        >

        <button class="button mr-3 bg-theme-1 text-white" @click="init(true)">
          <i class="el-icon-refresh-left mr-1"></i>
          <span>刷新</span>
        </button>
      </div>
    </div>

    <div class="report-box zoom-in box p-4 intro-y" style="cursor: auto">
      <el-form
        ref="ruleForm"
        :inline="true"
        :model="formData"
        class="flex items-center"
        style="width: 100%"
        size="small"
      >
        <div class="flex pt-4 pb-4" style="justify-content: space-between; width: 100%">
          <el-form-item label="应用名称" prop="merchant_name">
            <el-input v-model="formData.merchant_name" placeholder="应用名称"></el-input>
          </el-form-item>

          <el-form-item label="类型" prop="state">
            <el-select v-model="formData.type" placeholder="全部">
              <el-option
                v-for="item in pay_type"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="状态" prop="state">
            <el-select v-model="formData.state" placeholder="全部">
              <el-option
                v-for="item in state_set"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <!-- </div> -->
          <!-- <div class="ml-auto"> -->
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="onSearch"
              >搜索</el-button
            >
            <el-button icon="el-icon-refresh-right" @click="resetForm('ruleForm')"
              >重置</el-button
            >
          </el-form-item>
          <!-- </div> -->
        </div>
      </el-form>

      <el-table
        ref="multipleTable"
        v-loading="tableLoading"
        :data="tableData"
        style="width: 100%"
      >
        <el-table-column prop="name" label="应用名称"> </el-table-column>
        <el-table-column prop="app_id" label="appid"> </el-table-column>
        <el-table-column label="类型">
          <template slot-scope="scope">
            <div>
              {{ scope.row.pay_type | getPay_type(pay_type) }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="state" label="状态">
          <template slot-scope="scope">
            <div :class="scope.row.state === '1' ? 'text-theme-9' : 'text-theme-6'">
              {{ scope.row.state === "1" ? "启用" : "禁用" }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="created_at" label="创建日期"> </el-table-column>
        <el-table-column label="操作" width="175">
          <template slot-scope="scope">
            <span class="mr-5 text-theme-1" @click="handleClick(scope.row)">编辑</span>

            <span
              class="mr-5 text-theme-1"
              @click="handleAuthorization(scope.row)"
            >授权模式</span>
            <!-- <a
              href="javascript:;"
              data-toggle="modal"
              data-target="#authorization-model"
              class="mr-5 text-theme-1"
              @click="handleAuthorization(scope.row)"
              >授权模式</a
            > -->

            <span class="text-theme-6" @click="handleDetele(scope.row)">删除</span>
          </template>
        </el-table-column>
      </el-table>

      <div class="flex mt-10">
        <el-pagination
          :current-page.sync="paginate.p"
          :page-size="paginate.limit"
          layout="total, prev, pager, next"
          :total="paginate.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>

    <CashBox ref="CashBox"></CashBox>

    <authorizationModel></authorizationModel>

    <AuthDialog :show-dialog="isAuthDialogVisible"
                :select-item="authDialogSelectItem"
                :mode-options="modeOptions"
                @handle-close="isAuthDialogVisible = false"
                @handle-success="init()"
    ></AuthDialog>

  </div>
</template>

<script>
import request from "@/utils/request";
import CashBox from "./components/Cashbox.vue";
import AuthDialog from './components/authorization-dialog'
import authorizationModel from "./components/authorization-model.vue";

export default {
  filters: {
    getPay_type(value, data) {
      return data.filter((item) => {
        return item.value === value;
      })[0].name;
    },
  },
  components: {
    AuthDialog,
    CashBox,
    authorizationModel,
  },
  data() {
    return {

      // 授权的Dialog
      isAuthDialogVisible: false,
      authDialogSelectItem: {},

      tableData: [],
      tableLoading: true,

      state_set: [], // 状态列表
      pay_type: [], // 类型列表
      paginate: {},
      modeOptions: [],
      formData: {
        name: "",
        pay_type: "",
        state: "",
        p: 1,
        size: 10,
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    // 授权模式
    handleAuthorization(data) {
      console.log(data)
      // cash("#authorization-model").modal("show")
      this.authDialogSelectItem = data
      this.isAuthDialogVisible = true
    },

    handleClick(data) {
      cash("#new-pay-config").modal("show");
      if (JSON.stringify(data) === "{}") {
        this.$refs["CashBox"].dialogTitle = "新增";
      } else {
        this.$refs["CashBox"].dialogTitle = "编辑";
      }
      this.$refs["CashBox"].formData = data;
    },

    init(refresh = false) {
      this.tableLoading = true;
      request({
        url: "/appManage/App/getAppList",
        method: "GET",
        data: this.formData,
      }).then((res) => {
        this.tableLoading = false;
        this.tableData = res.data.list;

        this.state_set = res.data.state_set;
        this.paginate = res.data.paginate;
        console.log(res.data.pay_type_set, 123);
        this.pay_type = res.data.pay_type_set;
        this.modeOptions = res.data.mode
        if (refresh) {
          this.$message.success("刷新成功");
        }
      });
    },
    // 搜索
    onSearch() {
      this.init();
    },
    // 重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.init(true);
    },
    // 单个删除
    handleDetele(data) {
      this.$confirm("此操作将永久删除该商户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          request({
            url: "/appManage/App/delApp",
            method: "POST",
            data: {
              ids: [data.id],
            },
          }).then((res) => {
            if (res.code === 0) {
              this.$message.success("删除成功");
              this.init();
            } else {
              console.error("未知错误：" + res.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    handleSizeChange(val) {
      this.formData.size = val
      this.init()
    },
    handleCurrentChange(val) {
      this.formData.p = val
      this.init()
    },
  },
};
</script>

<style lang="scss" scoped></style>
