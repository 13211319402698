<template>
  <!-- dialog -->
  <Dialog :is-show="showDialog" :title="dialogTitle" :sub-title="dialogSubTitle" :dialog-width="dialogWidth" :dialog-height="dialogHeight" is-hide-bottom
          @handle-close="handleClose()"
          @handle-submit="submitForm()">
    <template slot="content">
      <el-form ref="formData" :model="formData" :rules="rules" label-width="auto">
        <!-- xx -->
        <div class="flex mb-4 flex-box">
          <!-- 平台模式 -->
          <div class="border text-gray-700 flex-item p-4 flex" :class="{ 'border-theme-1': formData.mode === '2' }" @click="formData.mode = '2'">
            <div class="img-box">
              <img src="@/assets/img/pintai.png" alt="" />
              <div class="text-md">平台模式</div>
            </div>
            <div>
              <p class="flex">
                <span class="mr-auto">模式介绍</span>
                <i class="el-icon-success" :style="formData.mode === '2' ? 'color:#1890FF' : 'color: #8e8e8e'"></i>
              </p>
              <div class="content-md">
                适合支付流水相对较小的企业使用，解决中小企业用户对接支付渠道难，对接门槛高，维护成本高问题，
                无需开发团队即可完成对接，成本更低，也可借助平台优势获得相对单一企业更高的收益，快速完成支付渠道对接，高效快捷。
              </div>
            </div>
          </div>
          <!-- 独立模式 -->
          <div class="border text-gray-700 flex-item p-4 flex" :class="{ 'border-theme-1': formData.mode === '1' }" @click="formData.mode = '1'">
            <div class="img-box">
              <img src="@/assets/img/duli.png" alt="" />
              <div class="text-md">独立模式</div>
            </div>
            <div>
              <p class="flex">
                <span class="mr-auto">模式介绍</span>
                <i class="el-icon-success" :style="formData.mode === '1' ? 'color:#1890FF' : 'color: #8e8e8e'"></i>
              </p>
              <div class="content-md">
                适用于支付流水相对较大且有一定开发能力的企业，可以通过支付联盟低成本对接支付宝、微信等主流支付平台，技术服务在线支持，省时省心。
              </div>
            </div>
          </div>
        </div>

        <!-- 平台模式 -->
        <div v-show="formData.mode === '2'" class="border text-gray-700 p-4 flex border-theme-1 body-theme-1">
          <div class="flex" style="width: 100%; justify-content: space-around">
            <div class="flex-item">
              <div class="text-base font-medium">平台模式概念</div>
              <div class="item-a-box">
                <img src="@/assets/img/platform.png" alt="" />
              </div>
            </div>

            <div class="flex-item">
              <div class="text-base font-medium">平台模式</div>
              <div class="item-a-box">
                <div style="height: 100%; flex-flow: column" class="flex">
                  <p class="mb-2">平台模式详细介绍</p>
                  <div class="item-text-a mb-auto">
                    适合支付流水相对较小的企业使用，解决中小企业用户对接支付渠道难，对接门槛高，维护成本高问题，
                    无需开发团队即可完成对接，成本更低，也可借助平台优势获得相对单一企业更高的收益，快速完成支付渠道对接，高效快捷。
                  </div>

                  <el-button :disabled="formData.mode === formData.oldMode" type="primary" class="w-48 mt-auto" @click="submitForm">使用该模式</el-button>
                  <p class="mt-2">
                    <el-radio v-model="formData.consent" :disabled="formData.mode === formData.oldMode" :label="true">同意支付联盟服务协议</el-radio>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 独立模式 -->
        <div v-show="formData.mode === '1'" class="border text-gray-700 p-4 flex border-theme-1 body-theme-1">
          <div class="flex" style="width: 100%; justify-content: space-around">
            <div class="flex-item">
              <div class="text-base flex font-medium">
                <span>独立模式概念</span>
                <span class="independent-icon">{{ formData.apply_status === 0 ? '未申请' : '到期时间：' + formData.validate_time }}</span>
              </div>
              <div class="item-a-box">
                <img src="@/assets/img/independent.png" alt="" />
              </div>
            </div>

            <div class="flex-items">
              <div class="text-base font-medium">服务期限</div>
              <div class="pt-2 pb-2">
                <el-radio-group v-model="formData.year_type">
                  <el-radio v-for="(item, index) in validateTypeOptions" :key="index" :label="item.year_type" border style="margin-right: 10px;">{{ item.year_type_str }}</el-radio>
<!--                  <el-radio :label="1">一年</el-radio>-->
<!--                  <el-radio :label="2">两年</el-radio>-->
<!--                  <el-radio :label="3">三年</el-radio>-->
                </el-radio-group>
              </div>
              <div class="text-base font-medium">支付金额</div>
              <div style="color: #ff4242; font-size: 10px" class="pt-2 pb-2">¥{{ needPayMoney }}</div>
              <div class="text-base font-medium">支付方式</div>
              <div class="pay-type-box">
                <div class="pay-type-item">
                  <div class="item-t" :style="formData.paymentType === 'alipay' ? 'color:#333333' : 'color: #999999'" @click="formData.paymentType = 'alipay'">
                    <img :src="alipayImg" alt="" />
                    <span>支付宝</span>
                    <i class="el-icon-success" :style="formData.paymentType === 'alipay' ? 'color:#1585FF' : 'color: #999999'"></i>
                  </div>
                  <div class="item-t" :style="formData.paymentType === 'wechat' ? 'color:#333333' : 'color: #999999'" @click="formData.paymentType = 'wechat'">
                    <img :src="wechatImg" alt="" />
                    <span>微信</span>
                    <i class="el-icon-success" :style="formData.paymentType === 'wechat' ? 'color:#1585FF' : 'color: #999999'"></i>
                  </div>
                </div>

                <el-button style="width: calc(50% - 10px); margin-top: 20px" type="primary" @click="submitForm">支付并使用该模式</el-button>
                <p class="mt-4">
                  <el-radio v-model="formData.consent" :label="true">同意支付联盟服务协议</el-radio>
                </p>
              </div>
            </div>
          </div>
        </div>

      </el-form>
    </template>
  </Dialog>
</template>

<script>
import Dialog from '@/views/Dialog'
import { fetchAuthData, editorAuthData } from '@/api/lm-pay-config/lm-pay-config'

export default {
  components: { Dialog },
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    selectItem: {
      type: Object,
      default: () => {}
    },
    modeOptions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      // 弹窗参数
      dialogTitle: '授权模式',
      dialogSubTitle: '',
      dialogWidth: '992px',
      dialogHeight: 'auto',
      // 提交数据
      formData: {
        mode: '',
        oldMode: '',
        // 同意协议
        consent: false,
        // 服务期限
        year_type: '',
        // 支付类型
        paymentType: '',
        // 申请状态
        apply_status: '',
        // 申请状态字符串
        apply_status_str: '',
        // 到期时间
        validate_time: ''
      },
      // 规则
      rules: {}
    }
  },
  computed: {
    // 服务期限时间
    validateTypeOptions() {
      try {
        return this.modeOptions[0].mode_set[0].validate_type
      } catch (e) {
        console.log('')
      }
      return undefined
    },
    // 需要支付的金额
    needPayMoney() {
      let rlt = 0
      try {
        rlt = this.validateTypeOptions.filter(item => item.year_type + '' === this.formData.year_type + '')[0].price
      } catch (e) {
        console.log('')
      }
      return rlt
    },
    alipayImg() {
      if (this.formData.paymentType === "alipay") {
        return require("@/assets/img/alipay-2.png");
      } else {
        return require("@/assets/img/alipay-1.png");
      }
    },
    wechatImg() {
      if (this.formData.paymentType === "wechat") {
        return require("@/assets/img/WeChat-2.png");
      } else {
        return require("@/assets/img/WeChat-1.png");
      }
    },
  },
  watch: {
    showDialog(val) {
      if (val) {
        this._initDataView()
      } else {
        this.$refs?.formData?.resetFields()
      }
    }
  },
  methods: {
    handleClose() {
      this.$emit('handle-close')
    },
    handleSuccess() {
      this.$emit('handle-success')
    },
    // 参数校验
    customVisible() {
      if (this.formData.year_type === undefined || this.formData.year_type.length === 0) {
        this.$message.warning('请选择服务期限～')
        return false
      }
      if (this.formData.paymentType === undefined || this.formData.paymentType.length === 0) {
        this.$message.warning('请选择支付类型～')
        return false
      }
      return true
    },
    // 初始化数据
    _initDataView() {
      console.log('初始化窗口')
      this.formData.mode = ''
      this.formData.oldMode = ''
      this.formData.year_type = ''
      this.formData.paymentType = ''
      this.formData.consent = false
      this._fetchData()
    },
    // 提交接口
    submitForm() {
      if (!this.formData.consent) {
        this.$message.warning('请勾选同意联盟服务协议～')
        return
      }
      // 如果是切换了类型，则提示警告
      if (this.formData.oldMode !== this.formData.mode) {
        console.log('提示弹窗')
      }
      if (this.formData.mode === '1') { // 独立模式
        // 校验通过并且支付成功才走
        if (this.customVisible()) {
          console.log('执行')
        }
      } else if (this.formData.mode === '2') { // 平台模式
        this._saveData()
      }
    },
    // 保存
    _saveData() {
      const params = {}
      params.app_id = parseInt(this.selectItem.id)
      params.mode = parseInt(this.formData.mode)
      params.year_type = this.formData.mode === '2' ? undefined : parseInt(this.formData.year_type)
      editorAuthData(params).then(resp => {
        if (resp.code === 0) {
          this.$message.success('保存成功～')
          this.handleClose()
          this.handleSuccess()
        } else {
          this.$message.error(resp.msg)
        }
      })
    },
    // 初始化
    _fetchData() {
      const params = {}
      params.app_id = this.selectItem.id
      fetchAuthData(params).then(resp => {
        if (resp.code === 0) {
          this.formData.mode = resp.data.mode
          this.formData.oldMode = resp.data.mode
          this.formData.validate_time = resp.data.validate_time
          this.formData.apply_status = parseInt(resp.data.apply_status)
          this.formData.apply_status_str = resp.data.apply_status_str
          this.dialogSubTitle = this.formData.mode === '1' ? '当前模式：独立模式' : '当前模式：平台模式'
          console.log('成功')
        } else {
          this.$message.error(resp.msg)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.modal__content {
  width: 992px;
  border-radius: 12px;
}
.flex-box {
  width: 100%;
  justify-content: space-between;
}

.flex-item {
  width: calc(50% - 13px);
  border-radius: 7px;
  cursor: pointer;
}

.flex-items {
  width: calc(50% - 20px);
  border-radius: 7px;
}

.img-box {
  width: 60px;
  display: flex;
  flex-flow: column;
  text-align: center;
  flex-shrink: 0;
  margin-right: 10px;
}

.img-box > image {
  width: 100%;
}

.text-md {
  font-size: 13px;
  margin-top: 5px;
}

.content-md {
  font-size: 12px;
  color: #999999;
  margin-top: 5px;
}

.body-theme-1 {
  width: 100%;
  border-radius: 12px;
  position: relative;
}

.item-a-box {
  width: 100%;
  height: 300px;
  background: #fcfcfd;
  border: 1px solid #f4f4f7;
  opacity: 1;
  border-radius: 11px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 18px;
}
.item-a-box > img {
  width: 480px;
}

.item-text-a {
  color: #999999;
  font-size: 8px;
}

.independent-icon {
  color: #3a96fb;
  font-size: 12px;
  margin-left: 5px;

  display: flex;
  align-items: center;
}

.independent-icon::before {
  content: "";
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #3a96fb;
  margin-right: 5px;
}

.pay-type-box {
  width: 100%;
  height: 184px;
  background: #fcfcfd;
  border: 1px solid #f4f4f7;
  opacity: 1;
  margin-top: 10px;
  border-radius: 9px;
  padding: 18px;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
}

.pay-type-item {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: auto;
}

.item-t {
  width: calc(50% - 10px);
  height: 36px;
  background: #efeff0;
  border: 1px solid #e2e8f0;
  opacity: 1;
  border-radius: 12px;
  box-sizing: border-box;
  padding: 6px 9px;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #999999;
  img {
    height: 100%;
    margin-right: 10px;
  }
  i {
    margin-left: auto;
    font-size: 18px;
  }
}
</style>